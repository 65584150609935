import type { AgeRange } from '@/core/entities'

/**
 * Get age by birthday
 * @param {string} birthday
 * @returns {number} age
 */
export const getAgeByBirthday = (birthday: string): number => {
  const today = new Date()
  const birthDate = new Date(birthday)
  let age = today.getFullYear() - birthDate.getFullYear()
  const month = today.getMonth() - birthDate.getMonth()
  const day = today.getDate() - birthDate.getDate()
  if (month < 0 || (month === 0 && day < 0)) age--
  return age
}

/**
 * Function to return the age range based on age
 * @param age in number
 * @param ageRanges is an Array of AgeRange[]
 * @returns AgeRange Object
 */
export const getAgeRangeFromAge = (
  userAge: number,
  ageRanges: AgeRange[],
): AgeRange | undefined => {
  return ageRanges.find((ageRange) => {
    const [initialAge, finalAge] = ageRange.range.split('-').map(Number)
    return userAge >= initialAge && userAge <= finalAge
  })
}

/**
 * Function to transform a string to 2 digit
 * @param num number in string
 * @returns string
 */
export function padTo2Digits(num: string) {
  return num.padStart(2, '0')
}
