import { navigate } from 'astro:transitions/client'
import { createMemo } from 'solid-js'

import { FavoriteBookButton, Image } from '@/components'
import type { Book } from '@/core/entities'
import { getCookie, getSpeechBookReadingUrl } from '@/utils'

import Typography from './Typography'
import { trackLinkClick } from '@/utils/analytics'

interface BookCardItemProps {
  showFavoriteButton?: boolean
  handleFavorite?: (bookId: number) => void
  booksDetail: Book
}

const BookCardItem = (props: BookCardItemProps) => {
  const jsonUser = getCookie('user')
  const user: App.Locals['user'] = jsonUser && JSON.parse(jsonUser)

  const bookHref = createMemo(() => {
    const bookId = props.booksDetail?.id
    if (props.booksDetail?.childBook?.progress) {
      return getSpeechBookReadingUrl(bookId)
    }
    return ['/book/detail', bookId].join('/')
  })

  const handleClickCard = () => {
    trackLinkClick('Book Card', { url: bookHref() })
    navigate(bookHref())
  }

  return (
    <div
      class="relative flex w-full max-w-[240px] cursor-pointer flex-col items-start justify-center gap-2  self-stretch rounded-xl bg-pale-violet-10 p-4 transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110"
      onClick={handleClickCard}
    >
      {props.showFavoriteButton && (
        <FavoriteBookButton
          bookId={props.booksDetail.id}
          isFavorite={props.booksDetail.childBook.isFavorite}
          childId={user.id}
          callbackFavotire={props.handleFavorite}
        />
      )}
      <Image
        class="h-[180px] w-full sm:h-[220px] md:h-[240px]"
        loading="lazy"
        srcSet={props.booksDetail.imageUrl}
        alt="Book Cover"
      />
      <div class="flex w-full flex-col items-center justify-start gap-1.5 pt-2">
        <Typography
          weight="medium"
          size="t-sm"
          element="span"
          class="text-center text-edsm-neutral-80"
        >
          {props.booksDetail.author}
        </Typography>
        <Typography
          weight="bold"
          size="t-md"
          element="h4"
          class="text-center text-edsm-neutral-100"
        >
          {props.booksDetail.name}
        </Typography>
      </div>
    </div>
  )
}

export default BookCardItem
